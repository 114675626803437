<template>
  <div class="news-page">
    <!-- 供需 -->
    <main-nav />
    <div class="news-bar">
      <div class="bannerWrapper">
        <img src="../../assets/supplyBanner.png" alt="" />
      </div>
      <div class="newsListNav">
        <div class="maskLayer"></div>
        <div class="ListNav">
          <div class="itemWrapper">
            <div
              @click="changeOptions(0)"
              :class="[typeMark === 0 ? 'active' : '']"
            >
              供应
            </div>
          </div>
          <div class="itemWrapper">
            <div
              @click="changeOptions(1)"
              :class="[typeMark !== 0 ? 'active' : '']"
            >
              需求
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 静态要写两个div判断是否出现或隐藏 -->
    <!-- 供应 -->
    <!-- 第一页 -->
    <transition name="fade" v-if="typeMark == 0 && currentPage == 1">
      <div class="newsWrapper">
        <div
          v-for="(item, index) in resdata"
          :key="'id' + index"
          class="newsItem"
        >
          <div class="newsLeftItem">
            <img
              v-if="item.contentImg.length > 0"
              :src="'https://admin.gdhhsh.cn' + item.contentImg[0].path"
            />
          </div>
          <div class="newsRightItem" @click="toDetail(item)">
            <p>{{ item.contentTitle }}</p>
            <div class="ItemContent newsOmit">
              {{ item.contentDescription }}
            </div>
            <div class="css_time">{{ item.contentDatetime }}</div>
          </div>
        </div>
      </div>
    </transition>

    <!-- 需求 -->
    <!-- 第一页 -->
    <transition name="fade" v-if="typeMark != 0 && currentPage == 1">
      <div class="newsWrapper">
        <div v-for="(item, index) in resdata2" :key="index" class="newsItem">
          <div class="newsLeftItem">
            <img
              v-if="item.contentImg.length > 0"
              :src="'https://admin.gdhhsh.cn' + item.contentImg[0].path"
            />
          </div>
          <div class="newsRightItem" @click="toDetail(item)">
            <p>{{ item.contentTitle }}</p>
            <div class="ItemContent newsOmit">
              {{ item.contentDescription }}
            </div>
            <div class="css_time">{{ item.contentDatetime }}</div>
          </div>
        </div>
      </div>
    </transition>
    <Footer />
  </div>
</template>

<script>
import MainNav from "../../components/MainNav/index";
import Footer from "../../components/Footer.vue";
import Qs from "qs";
import { supplyanddemand, demandlist, Newsdetail } from "@/api/fetch";
export default {
  name: "eleDem",
  data() {
    return {
      typeMark: 0, // 默认为 供应
      pageNum: 2, // 总页
      pagenum: 1, // 总页
      currentPage: 1, // 当前页
      stripNum: 6, // 页面条数
      resdata: [],
      resdata2: [],
    };
  },
  components: {
    MainNav,
    Footer,
  },
  mounted() {
    let that = this;
    this.$nextTick(() => {
      // this.initScroll()
      window.onscroll = function () {
        //变量scrollTop是滚动条滚动时，距离顶部的距离
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        //变量windowHeight是可视区的高度
        var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        //变量scrollHeight是滚动条的总高度
        var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        //滚动条到底部的条件
        if (scrollTop + windowHeight == scrollHeight) {
          //写后台加载数据的函数 一定要用that
          that.pagenum++;
          that.datalist();
        }
      };
    });
    this.typeMark = this.$route.params.newType ? this.$route.params.newType : 0;
    this.datalist();
  },
  methods: {
    datalist() {
      //供应
      let data = {
        categoryId: "11",
        contentDisplay: "0",
        pageNo: this.pagenum,
      };
      supplyanddemand(Qs.stringify(data)).then((res) => {
        res.data.data.rows.forEach((element) => {
          element.contentImg = JSON.parse(element.contentImg);
          this.resdata.push(element);
        });
      });
      //需求
      let obj = { categoryId: "12", contentDisplay: "0", pageNo: this.pagenum };
      demandlist(Qs.stringify(obj)).then((res) => {
        res.data.data.rows.forEach((element) => {
          element.contentImg = JSON.parse(element.contentImg);
          this.resdata2.push(element);
        });
      });
    },

    changeOptions(bool) {
      this.typeMark = bool;
      this.currentPage = 1;
      if (bool == 0) {
        this.pageNum = 2;
      } else {
        this.pageNum = 1;
      }
    },
    // 点击跳转到详情页
    toDetail(item) {
      let obj = {
        id: item.id,
        contentDisplay: "0",
      };
      Newsdetail(obj).then((res) => {
        let strItem = JSON.stringify(item);
        this.$router.push({
          name: "News1",
        });
        this.$router.push(
          `/News1/` + encodeURIComponent(strItem).replace(/%/g, "%25")
        );
      });
    },
  },
};
</script>

<style scoped lang="less">
.css_time {
  position: absolute;
  bottom: 0px;
  right: 24px;
}
@import "../../styles/news";
.news-page {
  .news-bar {
    height: auto;
    position: relative;
    margin-bottom: 2%;
    .bannerWrapper {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .newsListNav {
      height: 8rem;
      position: absolute;
      z-index: 0;
      bottom: 0;
      width: 100%;
      .maskLayer {
        position: absolute;
        background-color: #000843;
        opacity: 0.66;
        width: 100%;
        height: 100%;
        left: 0;
      }
      .ListNav {
        position: relative;
        z-index: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        .itemWrapper {
          display: flex;
          justify-content: center;
          align-items: stretch;
          div {
            width: 11.5rem;
            margin: 0 4rem;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
            font-size: 1.8rem;
            color: rgb(176, 194, 238);
            cursor: pointer;
            &:hover {
              color: #ffffff;
            }
          }
          .active {
            border-bottom: 0.33rem solid #fbde00;
            color: #fff;
          }
        }
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .pagenav {
    margin-top: 2.71%;
    margin-bottom: 4.67%;
    text-align: center;
    .pagination {
      li {
        .active {
          background-color: #2b8dfa;
          border-color: #2b8dfa;
          color: #ffffff;
        }
        a {
          margin-right: 0.83rem;
        }
        a:hover {
          border-color: #2b8dfa;
          background-color: #ffffff;
        }
        a:focus {
          background-color: #2b8dfa;
          border-color: #2b8dfa;
          color: #ffffff;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .news-page .news-bar /deep/.newsListNav {
    height: 6rem;
    .ListNav/deep/ {
      margin-right: 15rem;
    }
  }
}
</style>
